import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "product",
    initialState: {
        products: [],
        stocks: [],
        colors: [],
        sliders: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getColorStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getColorSuccess: (state, action) => {
            state.isFetching = false;
            state.colors = action.payload;
        },
        getColorFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getSliderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSliderSuccess: (state, action) => {
            state.isFetching = false;
            state.sliders = action.payload;
        },
        getSliderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products.splice(
                state.products.findIndex((item) => item._id === action.payload),
            );
        },
        deleteProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        deleteSliderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteSliderSuccess: (state, action) => {
            state.isFetching = false;
            state.products.splice(
                state.sliders.findIndex((item) => item._id === action.payload),
            );
        },
        deleteSliderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products[
                state.products.findIndex(
                    (item) => item._id === action.payload.id
                )
            ] = action.payload.product;
        },
        updateProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products.push(action.payload);
        },
        addProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        addColorStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addColorSuccess: (state, action) => {
            state.isFetching = false;
            state.colors.push(action.payload);
        },
        addColorFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        addSliderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addSliderSuccess: (state, action) => {
            state.isFetching = false;
            state.sliders.push(action.payload);
        },
        addSliderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getAccessoryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getAccessorySuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getAccessoryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },


        confirmOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        confirmOrderSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        confirmOrderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        shipOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        shipOrderSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        shipOrderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        cancelOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        voidOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        cancelOrderSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        cancelOrderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        addStockStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addStockSuccess: (state, action) => {
            state.isFetching = false;
            state.stocks.push(action.payload);
        },
        addStockFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getStockStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getStockSuccess: (state, action) => {
            state.isFetching = false;
            state.stocks = action.payload;
        },
        getStockFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        updateStockStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateStockSuccess: (state, action) => {
            state.isFetching = false;
            state.stocks[
                state.stocks.findIndex(
                    (item) => item._id === action.payload.id
                )
            ] = action.payload.product;
        },
        updateStockFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getTransactionStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getTransactionSuccess: (state, action) => {
            state.isFetching = false;
            state.stocks = action.payload;
            if (action.payload.filters.limit !== 10) {
                alert(`${action.payload.payload.length} items are loaded for consignment and return.`)
            }

        },
        getTransactionFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getProductReportStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProductReportSuccess: (state, action) => {
            state.isFetching = false;
        },
        getProductReportFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        updateProductLabelStart: (state, action) => {
            state.isFetching = false;
            state.stocks = action.payload;
        },
        updateProductLabelFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getProductStart,
    getProductSuccess,
    getProductFailure,
        getColorStart,
    getColorSuccess,
    getColorFailure,
    getSliderStart,
    getSliderSuccess,
    getSliderFailure,
    getAccessoryStart,
    getAccessorySuccess,
    getAccessoryFailure,
    deleteProductStart,
    deleteProductSuccess,
    deleteProductFailure,
        deleteSliderStart,
    deleteSliderSuccess,
    deleteSliderFailure,
    updateProductStart,
    updateProductSuccess,
    updateProductFailure,
    addProductStart,
    addProductSuccess,
    addProductFailure,
        addColorStart,
    addColorSuccess,
    addColorFailure,
    addSliderStart,
    addSliderSuccess,
    addSliderFailure,
    confirmOrderStart,
    confirmOrderSuccess,
    confirmOrderFailure,
    shipOrderStart,
    shipOrderSuccess,
    shipOrderFailure,
    cancelOrderStart,
    cancelOrderSuccess,
    cancelOrderFailure,
    voidOrderStart,
    addStockStart,
    addStockSuccess,
    addStockFailure,
    getStockStart,
    getStockSuccess,
    getStockFailure,
    updateStockStart,
    updateStockSuccess,
    updateStockFailure,
    getTransactionStart,
    getTransactionSuccess,
    getTransactionFailure,
    getProductReportStart,
    getProductReportSuccess,
    getProductReportFailure,
    updateProductLabelStart,
    updateProductLabelFailure
} = productSlice.actions;

export default productSlice.reducer;
