import React from "react";

const Modal = ({ reset, children }) => {
  return (
    <div className="py-32 min-h-screen w-full fixed inset-0 overflow-auto">
      <div
        className="tint h-full w-full fixed inset-0 bg-black bg-opacity-25"
        onClick={() => {
          reset();
        }}
      />
      <div className="absolute left-1/2 w-70vw p-16 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4">
        {children}
      </div>
    </div>
  );
};

export default Modal;
