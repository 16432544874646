import { DataGrid } from "@material-ui/data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSliders, deleteSlider } from "../../redux/apiCalls";
import { DeleteOutline } from "@material-ui/icons";

export default function SliderList() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.sliders);

const handleDelete = (id) => {
        deleteSlider(id, dispatch);
    };
                       

    useEffect(() => {
        getSliders(dispatch);
    }, [dispatch]);



    const columns = [
        {
            field: "url",
            headerName: "URL",
            width: 400,
            sortable: false,
        },
        {
            field: "img",
            headerName: "Image",
            width: 400,
            sortable: false,
            renderCell: (params) => (
                <a href={params.row.img} target="_blank" rel="noopener noreferrer">
                    <img
                        src={params.row.img}
                        alt="Product"
                        style={{ width: "100px", height: "auto", cursor: "pointer" }}
                    />
                </a>
            ),
        },
        {
            field: "mobileImg",
            headerName: "Mobile Image",
            width: 400,
            sortable: false,
            renderCell: (params) => (
                <a href={params.row.mobileImg} target="_blank" rel="noopener noreferrer">
                    <img
                        src={params.row.mobileImg}
                        alt="Mobile Product"
                        style={{ width: "100px", height: "auto", cursor: "pointer" }}
                    />
                </a>
            ),
        },
        {
            field: "delete",
            headerName: "Delete",
            width: 400,
            sortable: false,
            renderCell: (params) => (
                    <DeleteOutline
                        className="productListDelete"
                        onClick={() => handleDelete(params.row._id)}
                    />
            ),
        },
       
    ];

    return (
        <div className="productList">
            <DataGrid
                rows={products}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={16}
                disableColumnMenu
            />
        </div>
    );
}
