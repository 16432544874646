import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Visibility } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProducts } from "../../redux/apiCalls";
import excel from "../../components/excel/excel";
import styled from "styled-components";
import Select from "react-select";
import _ from "lodash";
import { userRequest } from "../../requestMethods";

const statusOptions = [
    {
        label: "Inactive",
        value: "inactive",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "All",
        value: "all",
    },
];

const typeOptions = [
    {
        label: "Watch",
        value: "watch",
    },
    {
        label: "Accessory",
        value: "accessory",
    },
    {
        label: "All",
        value: "all",
    },
];

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const EmptyModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

const ColorPickerContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 101;
`;

const ColorPicker = styled.img`
    margin: 20px 10px;
    cursor: pointer;
    width: 90%;
    height: 30vh;
    object-fit: contain;
    background: white;
`;

const ColorPickerWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const ActionButton = styled.button`
    position: absolute;
    right: 5px;
    top: 5px;
    color: ${({ isActive }) => (isActive ? "black" : "white")};
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export default function ProductList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );
    const products = useSelector((state) => state.product.products);
    const [filterCategory, setFilterCategory] = useState({
        status: "all",
        model: undefined,
        type: "all"
    });
    const [colorpicker, setColorPicker] = useState(false);
    const [color, setColor] = useState({});
    const [modalData, setModalData] = useState(undefined);

    useEffect(() => {
        if (colorpicker === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [colorpicker]);

    useEffect(() => {
        getProducts(dispatch, filterCategory);
    }, [dispatch, filterCategory]);

    const handleDelete = (id) => {
        deleteProduct(id, dispatch);
    };

    const handleDeleteColor = async(id) => {
         const result = await userRequest.put(`/products/deleteColor/${modalData}`, {id: id});
            if (result.status === 200) {
                alert("Color deleted successfully");
                getProducts(dispatch, filterCategory);
                setColorPicker(false);
            }
    };
    
    const handleTickColor = async(id) => {
        const result = await userRequest.put(`/products/activateColor/${modalData}`, {id: id});
        if (result.status === 200) {
            alert("Color reactivated successfully");
            getProducts(dispatch, filterCategory);
            setColorPicker(false);
        }
    };

    const handleFilter = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleStatus = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleType = (e) => {
        setFilterCategory((prev) => {
            return { ...prev, type: e.value };
        });
    };


    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Visibility
                            className="marginRight cursor-pointer"
                            onClick={() => {
                                setColor(params.row.color);
                                setModalData(params.row._id);
                                window.scrollTo(0, 0);
                                setColorPicker(true);
                            }}
                        />
                        {superadmin && (
                            <DeleteOutline
                                className="productListDelete"
                                onClick={() => handleDelete(params.row._id)}
                            />
                        )}
                    </>
                );
            },
        },
        {
            field: "product",
            headerName: "Model",
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="productListItem">
                        {params.row.model}
                    </div>
                );
            },
        },
        { field: "title", headerName: "Name", width: 400, sortable: false },
        {
            field: "price",
            headerName: "Price",
            width: 160,
            sortable: false,
            renderCell: (params) => {
                const pricing =
                    process.env.REACT_APP_COUNTRY === "SINGAPORE"
                        ? params.row.price
                        : params.row.myprice;
                return (
                    <>
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}
                        {pricing ? pricing.toLocaleString("en-US") : "0"}
                        .00
                    </>
                );
            },
        },
        {
            field: "cutprice",
            headerName: "Discounted Price",
            width: 160,
            sortable: false,
            renderCell: (params) => {
                const pricing =
                   params.row.cutprice
                return (
                    <>
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}
                        {pricing ? pricing.toLocaleString("en-US") : "0"}
                        .00
                    </>
                );
            },
        },
        {
            field: "action",
            headerName: "Update",
            width: 600,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to={"/product/" + params.row._id}
                                >
                                    <button className="red productListEdit">
                                        Edit
                                    </button>
                                </Link>

                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to={"/productcolor/" + params.row._id}
                                >
                                    <button className="blue productListEdit">
                                        Color
                                    </button>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to={"/productgallery/" + params.row._id}
                                >
                                    <button className="yellow productListEdit">
                                        Gallery
                                    </button>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to={"/productstatus/" + params.row._id}
                                >
                                    <button className="productListEdit">
                                        Label
                                    </button>
                                </Link>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div className="productList">
                <div className="samerow">
                <div className="addProductItem marginRight">
                    <input
                        className="inputFilter"
                        name="model"
                        value={filterCategory.model}
                        type="string"
                        placeholder="Model"
                        onChange={handleFilter}
                    />
                </div>
                <div className="sameRowItem marginRight">
                        <Select
                            options={typeOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleType}
                            value={{label: _.capitalize(filterCategory.type), value: filterCategory.type}}
                            placeholder="Search Type"
                        />
                    </div>
                <div className="sameRowItem marginRight">
                        <Select
                            options={statusOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleStatus}
                            value={{label: _.capitalize(filterCategory.status), value: filterCategory.status}}
                            placeholder="Search Status"
                        />
                    </div>
               
                </div>


            <div className="block">
            {superadmin && (
                    <button
                        onClick={() => {
                            let payload = [];
                            products.forEach((product) => {
                                let formattedExcelColor = "";
                                let formattedExcelCategory = "";
                                product.color.map(
                                    (color) =>
                                        (formattedExcelColor =
                                            formattedExcelColor +
                                            "," +
                                            color.name)
                                );
                                product.categories.map(
                                    (category) =>
                                        (formattedExcelCategory =
                                            formattedExcelCategory +
                                            "," +
                                            category)
                                );
                                payload.push({
                                    ...product,
                                    formattedExcelColor:
                                        formattedExcelColor.slice(1),
                                    formattedExcelCategory:
                                        formattedExcelCategory.slice(1),
                                    price:
                                        process.env.REACT_APP_COUNTRY ===
                                        "SINGAPORE"
                                            ? product.price
                                            : product.myprice,
                                });
                            });
                            excel({
                                excelData: payload,
                                fileName: "HaofaProduct",
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                )}
            </div>

            <DataGrid
                rows={products}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={16}
                disableColumnMenu
            />
{colorpicker && (
    <OverlayContainer>
        <ModalContainer></ModalContainer>
        <Overlay>
            <EmptyModalDetails onClick={() => setColorPicker(false)}></EmptyModalDetails>
            <ModalDetails>
                <ModalItemContainer>
                    <ColorPickerContainer>
                        {color
                            ? color.map((color, i) => (
                                  <ColorPickerWrapper key={i}>
                                      <ColorPicker
                                          src={color.img}
                                          onClick={() => {
                                              setColorPicker(false);
                                          }}
                                      />
                                      {(superadmin || marketing) && ( // Only show ActionButton if superadmin is true
                                          <ActionButton
                                              isActive={color.status === "active"}
                                              onClick={() =>
                                                  color.status === "active"
                                                      ? handleDeleteColor(color._id)
                                                      : handleTickColor(color._id)
                                              }
                                          >
                                              {color.status === "active" ? "✖" : "✔"}
                                          </ActionButton>
                                      )}
                                  </ColorPickerWrapper>
                              ))
                            : ""}
                    </ColorPickerContainer>
                </ModalItemContainer>
            </ModalDetails>
        </Overlay>
    </OverlayContainer>
)}

        </div>
    );
}
