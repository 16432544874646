import { DataGrid } from "@material-ui/data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColors } from "../../redux/apiCalls";

export default function ColorList() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.colors);



    useEffect(() => {
        getColors(dispatch);
    }, [dispatch]);



    const columns = [
    
        { field: "name", headerName: "Code", width: 300, sortable: false },
        { field: "color", headerName: "Color", width: 400, sortable: false },
    ];

    return (
        <div className="productList">

            <DataGrid
                rows={products}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={16}
                disableColumnMenu
            />
        </div>
    );
}
