import { useLocation } from "react-router-dom";
import "./productColor.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { generateSignature, addProductColor, getColors } from "../../redux/apiCalls";
import axios from "axios";

export default function ProductColor() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [inputs, setInputs] = useState({name: "BLK"});
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    const colorNames = useSelector((state) => state.product.colors);

        useEffect(() => {
            getColors(dispatch);
        }, [dispatch]);

    const handleColor = (e) => {
        setInputs((prev) => {
            return { ...prev, name: e.target.value };
        });
    };

    const handleClick = async () => {
        if (file == null) {
            return alert("Image is required for creating new product");
        }

        const fileName = new Date().getTime() + file.name;
        const imageresult = await generateSignature();
        const imageFormData = new FormData();

        imageFormData.append("file", file);
        imageFormData.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData.append("signature", imageresult.data.signature);
        imageFormData.append("expire", imageresult.data.expire);
        imageFormData.append("token", imageresult.data.token);
        imageFormData.append(
            "fileName",
            `${fileName} - ${imageresult.data.expire}`
        );
        imageFormData.append("folder", "haofa");

        const imageUploadResult = await axios.post(
            "https://upload.imagekit.io/api/v1/files/upload",
            imageFormData,
            { headers: {'Content-Type': 'multipart/form-data'} }
        );

        //SHOULD PUSH COLOR INTO ARRAY NOT REPLACE THE ENTIRE THING
        const updatedProduct = {
            img: imageUploadResult.data.url, name: inputs.name 
        };
        addProductColor(product._id, updatedProduct, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Add Product Color</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>Color</label>
                    <select name="color" onChange={handleColor}>
                        {colorNames.map((color, index) => (
                            <option key={index} value={color.name}>
                                {color.color}
                            </option>
                        ))}
                    </select>
                    </div>
                <button onClick={handleClick} className="addProductButton">
                    Add Product Color
                </button>
            </div>
        </div>
    );
}
