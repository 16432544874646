import { useState } from "react";
import "./newProduct.css";
import { addColor } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

export default function NewProduct() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };


    const handleClick = async () => {
        const product = {
            ...inputs,
        };
        addColor(product, dispatch);
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Color</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Code</label>
                    <input
                        name="name"
                        type="text"
                        placeholder="Color Code"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Color</label>
                    <input
                        name="color"
                        type="text"
                        placeholder="Color Name"
                        onChange={handleChange}
                    />
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
