import { useState } from "react";
import "./newProduct.css";
import { addSlider, generateSignature } from "../../redux/apiCalls";
import axios from "axios";
import { useDispatch } from "react-redux";

export default function NewProduct() {
    const [inputs, setInputs] = useState({});
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };


    const handleClick = async () => {
        if (file == null) {
            return alert("Image is required for creating new product");
        }

        const fileName = new Date().getTime() + file.name;
        const imageresult = await generateSignature();
        const imageFormData = new FormData();

        imageFormData.append("file", file);
        imageFormData.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData.append("signature", imageresult.data.signature);
        imageFormData.append("expire", imageresult.data.expire);
        imageFormData.append("token", imageresult.data.token);
        imageFormData.append(
            "fileName",
            `${fileName} - ${imageresult.data.expire}`
        );
        imageFormData.append("folder", "haofa");

        const imageUploadResult = await axios.post(
            "https://upload.imagekit.io/api/v1/files/upload",
            imageFormData,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                },
            }
        );

        if (file == null) {
            return alert("Image is required for creating new product");
        }

        const fileName2 = new Date().getTime() + file2.name;
        const imageresult2 = await generateSignature();
        const imageFormData2 = new FormData();

        imageFormData2.append("file", file2);
        imageFormData2.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData2.append("signature", imageresult2.data.signature);
        imageFormData2.append("expire", imageresult2.data.expire);
        imageFormData2.append("token", imageresult2.data.token);
        imageFormData2.append(
            "fileName",
            `${fileName2} - ${imageresult2.data.expire}`
        );
        imageFormData2.append("folder", "haofa");

        const imageUploadResult2 = await axios.post(
            "https://upload.imagekit.io/api/v1/files/upload",
            imageFormData2,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                },
            }
        );

        const product = {
            ...inputs,
            img: imageUploadResult.data.url,
            mobileImg: imageUploadResult2.data.url,
        };
        addSlider(product, dispatch);
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Slider</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>Mobile Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile2(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>URL</label>
                    <input
                        name="url"
                        type="text"
                        placeholder="Website URL"
                        onChange={handleChange}
                    />
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
