import { useState, useEffect } from "react";
import "./newProduct.css";
import { addAccessory, getColors } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

export default function NewProduct() {
    const [inputs, setInputs] = useState({ price: 9999 });
    const dispatch = useDispatch();


    useEffect(() => {
        getColors(dispatch);
    }, [dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };


    const handleClick = async () => {
        
        const product = {
            ...inputs,
        };
        addAccessory(product, dispatch);
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Accessory</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Title</label>
                    <input
                        name="title"
                        type="text"
                        placeholder="Product Title"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>MY Price</label>
                    <input
                        name="myprice"
                        type="number"
                        placeholder="Enter Price"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Model</label>
                    <input
                        name="model"
                        type="text"
                        placeholder="Model"
                        onChange={handleChange}
                    />
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
