import { useLocation } from "react-router-dom";
import "./product.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProduct } from "../../redux/apiCalls";
import axios from "axios";
import { generateSignature } from "../../redux/apiCalls";

export default function Product() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const [cover, setCover] = useState(undefined);
    const [mobilecover, setMobileCover] = useState(undefined);
    const [coverbg, setCoverbg] = useState(undefined);

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleClick = async () => {
        let mobilecoverimageUploadResult = undefined;
        let coverimageUploadResult = undefined;
        let coverbgimageUploadResult = undefined;

        if (cover) {
            const coverfileName = new Date().getTime() + cover.name;
            const coverimageresult = await generateSignature();
            const coverimageFormData = new FormData();

            coverimageFormData.append("file", cover);
            coverimageFormData.append(
                "publicKey",
                "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
            );
            coverimageFormData.append(
                "signature",
                coverimageresult.data.signature
            );
            coverimageFormData.append("expire", coverimageresult.data.expire);
            coverimageFormData.append("token", coverimageresult.data.token);
            coverimageFormData.append(
                "fileName",
                `${coverfileName} - ${coverimageresult.data.expire}`
            );
            coverimageFormData.append("folder", "haofa");

            coverimageUploadResult = await axios.post(
                "https://upload.imagekit.io/api/v1/files/upload",
                coverimageFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
        }

        if (mobilecover) {
            const mobilecoverfileName = new Date().getTime() + mobilecover.name;
            const mobilecoverimageresult = await generateSignature();
            const mobilecoverimageFormData = new FormData();

            mobilecoverimageFormData.append("file", cover);
            mobilecoverimageFormData.append(
                "publicKey",
                "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
            );
            mobilecoverimageFormData.append(
                "signature",
                mobilecoverimageresult.data.signature
            );
            mobilecoverimageFormData.append("expire", mobilecoverimageresult.data.expire);
            mobilecoverimageFormData.append("token", mobilecoverimageresult.data.token);
            mobilecoverimageFormData.append(
                "fileName",
                `${mobilecoverfileName} - ${mobilecoverimageresult.data.expire}`
            );
            mobilecoverimageFormData.append("folder", "haofa");

            mobilecoverimageUploadResult = await axios.post(
                "https://upload.imagekit.io/api/v1/files/upload",
                mobilecoverimageFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
        }

        if (coverbg) {
            const coverbgfileName = new Date().getTime() + coverbg.name;
            const coverbgimageresult = await generateSignature();
            const coverbgimageFormData = new FormData();

            coverbgimageFormData.append("file", coverbg);
            coverbgimageFormData.append(
                "publicKey",
                "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
            );
            coverbgimageFormData.append(
                "signature",
                coverbgimageresult.data.signature
            );
            coverbgimageFormData.append(
                "expire",
                coverbgimageresult.data.expire
            );
            coverbgimageFormData.append("token", coverbgimageresult.data.token);
            coverbgimageFormData.append(
                "fileName",
                `${coverbgfileName} - ${coverbgimageresult.data.expire}`
            );
            coverbgimageFormData.append("folder", "haofa");

            coverbgimageUploadResult = await axios.post(
                "https://upload.imagekit.io/api/v1/files/upload",
                coverbgimageFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
        }

        const updatedProduct = {
            ...product,
            title: inputs.title ? inputs.title : product.title,
            desc: inputs.desc ? inputs.desc : product.desc,
            price: inputs.price ? inputs.price : product.price,
            myprice: inputs.myprice ? inputs.myprice : product.myprice,
            cutprice: inputs.cutprice ? inputs.cutprice : product.cutprice,
            caseDiameter: inputs.caseDiameter
                ? inputs.caseDiameter
                : product.caseDiameter,
            caseMaterial: inputs.caseMaterial
                ? inputs.caseMaterial
                : product.caseMaterial,
            caseThickness: inputs.caseThickness
                ? inputs.caseThickness
                : product.caseThickness,
            drive: inputs.drive ? inputs.drive : product.drive,
            mirror: inputs.mirror ? inputs.mirror : product.mirror,
            model: inputs.model ? inputs.model : product.model,
            movementModel: inputs.movementModel
                ? inputs.movementModel
                : product.movementModel,
            powerReserve: inputs.powerReserve
                ? inputs.powerReserve
                : product.powerReserve,
            productId: inputs.productId ? inputs.productId : product.productId,
            strapMaterial: inputs.strapMaterial
                ? inputs.strapMaterial
                : product.strapMaterial,
            strapWidth: inputs.strapWidth
                ? inputs.strapWidth
                : product.strapWidth,
            vibrationFrequency: inputs.vibrationFrequency
                ? inputs.vibrationFrequency
                : product.vibrationFrequency,
            waterResistance: inputs.waterResistance
                ? inputs.waterResistance
                : product.waterResistance,
            windingMethod: inputs.windingMethod
                ? inputs.windingMethod
                : product.windingMethod,
            status: inputs.status ? inputs.status : product.status,
            video: inputs.video ? inputs.video : product.video,
            coverbg: coverbg
                ? coverbgimageUploadResult.data.url
                : product.coverbg,
            cover: cover
                ? coverimageUploadResult.data.url
                : product.cover,
                mobilecover: mobilecover
                ? mobilecoverimageUploadResult.data.url
                : product.mobilecover,
        };
        await updateProduct(product._id, updatedProduct, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Update Product</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Title</label>
                    <input
                        name="title"
                        type="text"
                        placeholder={product.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Price</label>
                    <input
                        name={
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "price"
                                : "myprice"
                        }
                        type="number"
                        placeholder={
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? product.price
                                : product.myprice
                        }
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Model</label>
                    <input
                        name="model"
                        type="text"
                        placeholder={product.model}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Movement Model</label>
                    <input
                        name="movementModel"
                        type="text"
                        placeholder={product.movementModel}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Power Reserve</label>
                    <input
                        name="powerReserve"
                        type="text"
                        placeholder={product.powerReserve}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Vibration Frequency</label>
                    <input
                        name="vibrationFrequency"
                        type="text"
                        placeholder={product.vibrationFrequency}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Drive</label>
                    <input
                        name="drive"
                        type="text"
                        placeholder={product.drive}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Winding Method</label>
                    <input
                        name="windingMethod"
                        type="text"
                        placeholder={product.windingMethod}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Case Diameter</label>
                    <input
                        name="caseDiameter"
                        type="text"
                        placeholder={product.caseDiameter}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Case Thickness</label>
                    <input
                        name="caseThickness"
                        type="text"
                        placeholder={product.caseThickness}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Strap Width</label>
                    <input
                        name="strapWidth"
                        type="text"
                        placeholder={product.strapWidth}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Case Material</label>
                    <input
                        name="caseMaterial"
                        type="text"
                        placeholder={product.caseMaterial}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Water Resistance</label>
                    <input
                        name="waterResistance"
                        type="text"
                        placeholder={product.waterResistance}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Mirror</label>
                    <input
                        name="mirror"
                        type="text"
                        placeholder={product.mirror}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Strap Material</label>
                    <input
                        name="strapMaterial"
                        type="text"
                        placeholder={product.strapMaterial}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Youtube Video</label>
                    <input
                        name="video"
                        type="text"
                        placeholder={product.video}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Cover</label>
                    <input
                        type="file"
                        id="cover"
                        onChange={(e) => setCover(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>Mobile Cover</label>
                    <input
                        type="file"
                        id="mobilecover"
                        onChange={(e) => setMobileCover(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>Cover Background</label>
                    <input
                        type="file"
                        id="coverbg"
                        onChange={(e) => setCoverbg(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>Discounted Price</label>
                    <input
                        name={
                           "cutprice"
                        }
                        type="number"
                        placeholder={
                            "Discounted Price, set to 0 for no discount"
                        }
                        defaultValue={product.cutprice}
                        onChange={handleChange}
                    />
                </div>
                <div
                    className={
                        process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "none"
                            : "addProductItem"
                    }
                >
                    <label>
                        Status
                    </label>
                    <select name="status" onChange={handleChange}>
                        {product.status === "active" ? (
                            <>
                                {" "}
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </>
                        ) : (
                            <>
                                {" "}
                                <option value="active">Active</option>
                                <option value="inactive" selected>Inactive</option>
                            </>
                        )}
                    </select>
                </div>
                <div
                    onClick={() => handleClick()}
                    className="updateProductButton"
                >
                    Update Product
                </div>
            </div>
        </div>
    );
}
